import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CHeroImg,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          logo: {
            src: '/assets/images/restaurants/keikaen/logo_keikaen.svg',
            alt: '桂花苑',
          },
          title: {
            ja: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[0]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect keikaen">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ sub: '桂花苑の魅力', main: 'ABOUT' }}
          />
          <h2 className="c_headingLv2">
            本格的な中国料理と創造的な空間が織りなす
            <br />
            「エキサイティングな食体験」
          </h2>
          <CHeroImg
            img={{
              src: '/assets/images/restaurants/keikaen/img_keikaen.png',
              alt: '',
            }}
          />
          <div className="p_keikaen">
            <p className="title">
              珠玉の中国料理を
              <br />
              もっと、愉しく。
            </p>
            <p className="content">
              自慢の中国料理もアップグレード。中国茶と点心をカジュアルに愉しむランチ、紹興酒と多彩な料理を自分スタイルで愉しむディナー。
              <br />
              <br />
              桂花苑ならではのメニューとアーティスティックな空間が織り成す、ここにしかないひとときが非日常に誘う。昼と夜の特別な時間を自分だけの愉しみ方で。
            </p>
          </div>
          <CHeroImg
            img={{
              src: '/assets/images/restaurants/keikaen/img_keikaen02.png',
              alt: '',
            }}
          />
          <div className="p_keikaen">
            <p className="title">
              至福のひとときを
              <br />
              もっと、贅沢に。
            </p>
            <p className="content">
              デザインコンセプトは、創造性×evolution（進化）。赤・黒・銅を基調とした店内と陰影を巧みに引き出す照明に照らされた空間は様々なシーンに寄り添いドラマティックに演出。
              <br />
              <br />
              スイングジャズが流れ、ほんのりと明かりが灯る店内で夜景とともに過ごすひとときはアニバーサリーやデートにおすすめです。
            </p>
          </div>
        </LWrap>
      </section>
      <section className="l_sect p_menu u_bgWhite">
        <LWrap>
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen03.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">RESTAURANT</span>
                    <p>レストラン</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      眺めの良いハイエンドな空間で多彩なメニューを堪能。桂花苑でしか味わえないエキサイティングな食体験を自分スタイルでおたのしみいただけます。
                    </p>
                  </>
                ),
                btn: {
                  label: 'レストラン',
                  link: {
                    href: '/restaurants/keikaen/restaurants/',
                  },
                  color: 'borderBlack',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen04.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">OIWAI</span>
                    <p>お祝い</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      ホテル最上階の寛げる空間は様々なシーンに対応。結婚式の二次会などのパーティーにも最適です。少人数をご希望の際は個室もご利用いただけます。
                    </p>
                  </>
                ),
                btn: {
                  label: 'お祝い',
                  link: {
                    href: '/restaurants/keikaen/oiwai/',
                  },
                  color: 'borderBlack',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen05.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">WEDDING</span>
                    <p>レストランウエディング</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      桂花苑ならではの本格的な中国料理を囲んで大切な方々との会話を愉しむ。ご家族やご友人だけでのアットホームな結婚式を叶えたい方におすすめです。
                    </p>
                  </>
                ),
                btn: {
                  label: 'レストランウエディング',
                  link: {
                    href: '/restaurants/keikaen/wedding/',
                  },
                  color: 'borderBlack',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/img_keikaen06.png',
                  alt: '',
                },
                title: (
                  <>
                    <span className="font-fCG">BUSINESS</span>
                    <p>宴会・会議</p>
                  </>
                ),
                text: (
                  <>
                    <p>
                      セミナーや企業の研修から懇親会までワンストップで対応。リゾート環境で生産性を高める、美味しい料理で親睦を深める。ビジネスユースに最適です。
                    </p>
                  </>
                ),
                btn: {
                  label: '宴会・会議',
                  link: {
                    href: '/restaurants/keikaen/business/',
                  },
                  color: 'borderBlack',
                },
              },
            ]}
            exClass="c_generalMedia_title"
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle
            title={{
              ja: '営業時間｜ご利用案内',
              en: <>GUIDE</>,
            }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
